import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import { KfHeroUtility } from "@klickinc/kf-react-components";
import '../styles.scss';

const HereNowKasners = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass("page--transcript page--transcript-here-now-kasners");
	}, []);

	return (
		<>
			<section className="transcript-section">
				<div className="container container--inner">
					<KfHeroUtility addedClass="heading-hero-blue heading">
						Transcript
					</KfHeroUtility>
					<p className="mb-4"><span className="uppercase">This video is based on the personal experience of families with boys taking emflaza<sup>®</sup></span> (deflazacort). <span className="uppercase">Please speak to a healthcare provider before making treatment decisions.</span></p>
					<h2 className="orange-heading">HERE & NOW WITH THE KASNERS: A DMD STORY</h2>
					<p className="text-base text-emflaza-orange-100">DMD=Duchenne Muscular Dystrophy</p>
					<div className="script">
						<div className="script--intro">
							<p><span className="name">Sarah:</span> <span className="copy">I'm Sarah.</span></p>
							<p><span className="name">Dan:</span> <span className="copy">I'm Dan.</span></p>
							<p><span className="name">Sarah:</span> <span className="copy">And we have four boys. AJ, Ben, Caleb, and Duncan. AJ is 21, Ben is 19, Caleb is eight, and Dunky is six. They like to go swimming, we love to travel with the boys, we love to go out to eat.</span></p>
						</div>
						<div className="script--body">
							<h3 className='highlighted-copy'>WHAT STANDS OUT TO YOU, LOOKING BACK AT THEIR DIAGNOSES?</h3>
							<p><span className="name">Sarah:</span> <span className="copy">Once we got home and, were able to kind of digest what we had been diagnosed with, we instantly started to advocate and just live differently, to be honest. So, started to kind of create some of the memories because we were worried that, we didn't know how long we would have them for.</span></p>
							<h3 className='highlighted-copy'>What Treatment Approach Did Your Sons' Care Team Suggest?</h3>
							<p><span className="name">Dan:</span> <span className="copy">Talking to doctors and the community members, we felt like we had figured out our choice and went down the right road.</span></p>
							<p><span className="name">Sarah:</span> <span className="copy">So we started Caleb first on EMFLAZA and then, subsequently, Dunky just a few weeks after.</span></p>
							<h3 className='highlighted-copy'>What Do You Do To Stay Healthy?</h3>
							<p><span className="name">Sarah:</span> <span className="copy">We love to make smoothies, we're a big smoothie family, I would say almost every day.</span></p>
							<h3 className='highlighted-copy'>How Have Other Parents Going Through a Similar Journey Helped?</h3>
							<p><span className="name">Sarah:</span> <span className="copy">I would say my friendship circle has changed. Not like we're just friends with other Duchenne families, but we get each other. And that's really helpful.</span></p>
							<h3 className='highlighted-copy'>A Closing Thought on the Here & Now</h3>
							<p><span className="name">Sarah:</span> <span className="copy">Living in the moment because we don't know how much time we have. I would say we feel lucky to be able to kind of live differently, with a purpose, with a mission.</span></p>
							<p><span className="name">Dan:</span> <span className="copy">And we continue to dig for different things that help them manifest that giggling and laughing and being silly and we don't ever know where we're going to find it next.</span></p>
							<div className="closing">
								<p className="highlighted-copy">ACTING LIKE BROTHERS BECASE THEY CAN</p>
								<br />
								<p className="highlighted-copy">ASK YOUR DOCTOR IF EMFLAZA CAN HELP</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HereNowKasners;

export function Head() {
	return (
		<Seo title="Video Transcript: Here & Now With the Kasners - EMFLAZA® (deflazacort)" addSocialMeta={false}>
			<meta name="robots" content="noindex" />
		</Seo>
	);
};
